import React from "react"
import { useForm } from "react-hook-form"
import { navigate } from "gatsby"

const SIBCommonSignupCTA = (props) => {
  const onSubmit = async (data) => {
    navigate("/signup", { state: { email: data.email } })
  }

  const { register, handleSubmit } = useForm({ reValidateMode: "onSubmit" })

  return (
    <section className="section">
      <div className="container">
        <div className="columns">
          <div className="column is-6 is-offset-3 has-text-centered">
            <div className="content">
              <h2 className="heading-large">
                Sign up today for your free trial
              </h2>
              <form onSubmit={handleSubmit(onSubmit)}>
                <div className="field is-grouped mb-3">
                  <p className="control is-expanded">
                    <input
                      {...register("email", {})}
                      className="input"
                      type="text"
                      placeholder="Email"
                    />
                  </p>
                  <p className="control">
                    <button className="button is-black" type="submit">
                      Sign up
                    </button>
                  </p>
                </div>
                <p className="is-size-6">
                  Free trial includes sample play scenes — No credit card needed
                </p>
              </form>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default SIBCommonSignupCTA
