import React, { useState } from "react"

import Slider from "react-slick"
import quoteMarkSVG from "../../../scss/img/quotemark.svg"

const SIBCommonTestimonials = (props) => {
  const [slider, setSlider] = useState(null)

  var sliderSettings = {
    autoplay: true,
    autoplaySpeed: 6000,
    dots: true,
    arrows: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  }

  const nextSlide = (e) => {
    e.preventDefault()
    slider.slickNext()
  }

  const prevSlide = (e) => {
    e.preventDefault()
    slider.slickPrev()
  }

  return (
    <>
      <section className="section bg-yellow">
        <div className="container">
          <Slider
            ref={(c) => setSlider(c)}
            {...sliderSettings}
            className="quote-slider"
          >
            <div>
              <figure>
                <blockquote>
                  <p className="quotemark">
                    <img src={quoteMarkSVG} alt="quote" />
                  </p>
                  <p>
                    By breaking the play into manageable “bits”, kids will not
                    only learn the play but will enjoy the Bard. A breathtaking
                    combination of high technology and classic literature.
                  </p>
                </blockquote>
                <figcaption>
                  — Jinny Gudmundsen, Editor, Computing With Kids magazine
                </figcaption>
              </figure>
            </div>
            <div>
              <figure>
                <blockquote>
                  <p className="quotemark">
                    <img src={quoteMarkSVG} alt="quote" />
                  </p>
                  <p>
                    It does a fabulous job of blending required reading, which
                    can sometimes seem out of touch for teens, with technology
                  </p>
                </blockquote>
                <figcaption>
                  — Amanda Bindel, Reviewer, Common Sense Education
                </figcaption>
              </figure>
            </div>
            <div>
              <figure>
                <blockquote>
                  <p className="quotemark">
                    <img src={quoteMarkSVG} alt="quote" />
                  </p>
                  <p>
                    The true wit and intelligence within Shakespeare’s language
                    can be appreciated rather than losing students because of
                    the inaccessible vocabulary.
                  </p>
                </blockquote>
                <figcaption>— Fiona Jostsons, Teacher</figcaption>
              </figure>
            </div>
          </Slider>
          {/*<!-- Slider Controls -->*/}
          <div className="slider-controls">
            <a
              className="slider-arrow slider-prev"
              role="button"
              href="/g"
              onClick={(e) => prevSlide(e)}
              onKeyDown={(e) => nextSlide(e)}
            >
              <svg width="30" height="30" xmlns="http://www.w3.org/2000/svg">
                <g
                  fill="none"
                  stroke="#000"
                  strokeWidth="2"
                  fillRule="evenodd"
                  strokeLinecap="round"
                >
                  <path strokeLinejoin="round" d="M15 29L1 15 15 1" />
                  <path d="M1 15h28.003" />
                </g>
              </svg>
            </a>
            <a
              className="slider-arrow slider-next"
              role="button"
              href="/"
              onClick={(e) => nextSlide(e)}
              onKeyDown={(e) => nextSlide(e)}
            >
              <svg width="30" height="30" xmlns="http://www.w3.org/2000/svg">
                <g
                  stroke="#000"
                  strokeWidth="2"
                  fill="none"
                  fillRule="evenodd"
                  strokeLinecap="round"
                >
                  <path strokeLinejoin="round" d="M15 1l14 14-14 14" />
                  <path d="M29 15H.997" />
                </g>
              </svg>
            </a>
          </div>
          {/*<!-- Slider Controls -->*/}
        </div>
      </section>
    </>
  )
}

export default SIBCommonTestimonials
